import { NgModule, importProvidersFrom } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { AuthInterceptor } from './core/interceptors/auth.interceptor';
import { RouterOutlet, provideRouter, withPreloading } from '@angular/router';
import { authGuard } from './core/guards/auth.guard';
import {
  notTermsAndConditionsGuard,
  termsAndConditionsGuard,
} from './core/guards/terms-and-conditions.guard';
import {
  ErrorStateMatcher,
  ShowOnDirtyErrorStateMatcher,
} from '@angular/material/core';
import { TermsAndConditionsLoggedInComponent } from '@modules/layout/terms-and-conditions-logged-in/terms-and-conditions-logged-in.component';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import { CustomPreloadingStrategy } from './core/preload-strategy';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';

@NgModule({
  declarations: [AppComponent],
  imports: [BrowserModule, BrowserAnimationsModule, RouterOutlet, NgIdleKeepaliveModule.forRoot()],
  providers: [
    provideEnvironmentNgxMask(),
    { provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher },
    importProvidersFrom([HttpClientModule, MatSnackBarModule, MatDialogModule]),
    { multi: true, provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor },
    provideRouter(
      [
        {
          canActivate: [],
          path: 'system',
          loadChildren: () =>
            import('./modules/system/system.routes').then(m => m.SYSTEM_ROUTES),
        },
        {
          canActivate: [],
          path: 'auth',
          loadChildren: () =>
            import('./modules/auth/auth.routes').then(m => m.AUTH_ROUTES),
        },
        {
          canActivate: [authGuard(), notTermsAndConditionsGuard()],
          path: 'terms-and-conditions',
          loadChildren: () =>
            import(
              './modules/terms-and-conditions/terms-and-conditions.routes'
            ).then(m => m.TERMS_AND_CONDITIONS_ROUTES),
        },
        {
          path: 'legal',
          redirectTo: 'legal/terms-and-conditions',
        },
        {
          path: 'legal/terms-and-conditions',
          component: TermsAndConditionsLoggedInComponent,
        },
        {
          canActivate: [authGuard(), termsAndConditionsGuard()],
          path: 'main',
          loadChildren: () =>
            import('./modules/layout/layout.routes').then(m => m.LAYOUT_ROUTES),
          data: { preload: true },
        },
        {
          path: '**',
          redirectTo: 'auth',
        },
      ],
      withPreloading(CustomPreloadingStrategy)
    ),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
