import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class SnackBarService {
  snackBarRef!: MatSnackBarRef<any>;

  constructor(private snackBar: MatSnackBar) {}

  public close() {
    this.snackBarRef?.dismiss();
  }

  public show(
    message: string,
    action = 'Close',
    config?: MatSnackBarConfig
  ): void {
    const defaultConfig: MatSnackBarConfig = {
      duration: 6000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
    };

    const mergedConfig = { ...defaultConfig, ...config };

    this.snackBarRef = this.snackBar.open(message, action, mergedConfig);
  }
}
