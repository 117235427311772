import { AfterViewInit, Component, Input } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { IUser, IUserInvite, ROLES } from '@app/modules/auth/interfaces/login.auth.interfaces';

@Component({
  selector: 'app-terms-and-conditions-text',
  templateUrl: './terms-and-conditions-text.component.html',
  styleUrls: ['./terms-and-conditions-text.component.sass'],
  standalone: true,
  imports: [
    MatDividerModule
  ]
})
export class TermsAndConditionsTextComponent implements AfterViewInit{
  @Input() user!: IUser | IUserInvite;
  public companyText = '';
  public companyName = 'Importer';
  delimiter = ',';
  ngAfterViewInit(): void {
    if(this.user.company.role.tag === ROLES.IMPORTER) {
      this.companyName = this.user.company.name.toUpperCase();
      this.companyText = `${this.user.company.name} has its registered address at ${this.user.company.legal_address}${this.delimiter}`;
    } else if(this.user.company.role.tag === ROLES.CONSUMER) {
      this.companyName = this.user.company.managedBy[0].name.toUpperCase();
    }
  }

}
