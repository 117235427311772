import { inject } from '@angular/core';
import { AuthService } from '../services/auth/auth.service';
import { CanActivateFn, Router } from '@angular/router';

export function authGuard(): CanActivateFn {
  return () => {
    const authService = inject(AuthService);
    const router = inject(Router);
    const user = authService.getUser();
    if (user.id) {
      return true;
    }
    router.navigate(['/auth']);
    return false;
  };
}

export function notAuthGuard(): CanActivateFn {
  return () => {
    const authService = inject(AuthService);
    const router = inject(Router);
    const user = authService.getUser();
    if (!user.id) {
      return true;
    }
    router.navigate(['/main']);
    return false;
  };
}
