import AGD_white_01 from './assets/branding/AGD_white_01.svg';
import Sustainable_by_AGD from './assets/branding/Sustainable_by_AGD.svg';
import Sustainable_by_AGD_white from './assets/branding/Sustainable_by_AGD_white.svg';
import check_successfully from './assets/icons/check_successfully.svg';
import companies_wo_results from './assets/icons/companies_wo_results.svg';
import directions_boat_darkish_pink from './assets/icons/directions_boat_darkish_pink.svg';
import expired from './assets/icons/expired.svg';
import logout from './assets/icons/logout.svg';
import maintenance from './assets/icons/maintenance.svg';
import pin_location_icon from './assets/icons/pin_location_icon.svg';
import plant from './assets/icons/plant.svg';
import soja from './assets/icons/soja.svg';
import spinner from './assets/icons/spinner.svg';
import user_wo_results from './assets/icons/user_wo_results.svg';
import vessels_wo_results from './assets/icons/vessels_wo_results.svg';
import vessels_wo_results_alt from './assets/icons/vessels_wo_results_alt.svg';
import whatsapp from './assets/icons/whatsapp.svg';
import without_notifications from './assets/icons/without_notifications.svg';
import without_results from './assets/icons/without_results.svg';
import AGD_General_Deheza from './assets/images/about-us/AGD_General_Deheza.jpg';
import AGD_Social from './assets/images/about-us/AGD_Social.jpg';
import argentina from './assets/images/about-us/argentina.svg';
import environmental_care from './assets/images/about-us/environmental_care.jpg';
import farming from './assets/images/about-us/farming.jpg';
import nca from './assets/images/about-us/nca.jpg';
import soja_green from './assets/images/about-us/soja_green.svg';
import T6 from './assets/images/about-us/T6.jpg';
import UNAgro from './assets/images/about-us/UNAgro.jpg';
import UNCO from './assets/images/about-us/UNCO.jpg';
import UNCO2 from './assets/images/about-us/UNCO2.jpg';
import UNMA from './assets/images/about-us/UNMA.jpg';
import UNMR from './assets/images/about-us/UNMR.jpg';
import crops from './assets/images/references/crops.jpg';
import forest from './assets/images/references/forest.jpg';
import no_vegetation from './assets/images/references/no_vegetation.jpg';
import water from './assets/images/references/water.jpg';

export const BRANDING = {
  AGD_white_01,
  Sustainable_by_AGD,
  Sustainable_by_AGD_white,
};

export const ICONS = {
  check_successfully,
  companies_wo_results,
  directions_boat_darkish_pink,
  expired,
  logout,
  maintenance,
  pin_location_icon,
  plant,
  soja,
  spinner,
  user_wo_results,
  vessels_wo_results,
  vessels_wo_results_alt,
  whatsapp,
  without_notifications,
  without_results,
};

export const ABOUT_US = {
  AGD_General_Deheza,
  AGD_Social,
  argentina,
  environmental_care,
  farming,
  nca,
  soja_green,
  T6,
  UNAgro,
  UNCO,
  UNCO2,
  UNMA,
  UNMR,
};

export const REFERENCES = {
  crops,
  forest,
  no_vegetation,
  water,
}
