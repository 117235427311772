import { Injectable } from '@angular/core';
import { LocalStorageService } from '../localStorage/local-storage.service';
import { CONSTANTS } from '@app/core/constants/user.constants';

@Injectable({
  providedIn: 'root',
})
export class UserDataService extends LocalStorageService {
  setUser(user: unknown): void {
    this.setItem(CONSTANTS.USER, user);
  }

  setPermissions(permissions: unknown): void {
    this.setItem(CONSTANTS.PERMISSIONS, permissions);
  }

  setRefreshToken(refreshToken: string) {
    this.setItem(CONSTANTS.REFRESH_TOKEN, refreshToken);
  }

  setToken(token: string) {
    this.setItem(CONSTANTS.TOKEN, token);
  }

  getRefreshToken(): string {
    return this.getItem(CONSTANTS.REFRESH_TOKEN) as string;
  }

  getToken(): string {
    return this.getItem(CONSTANTS.TOKEN) as string;
  }
  setArcgisToken(arcgisToken: string) {
    this.setItem(CONSTANTS.ARCGIS_TOKEN, arcgisToken);
  }
  getArcgisToken(): string {
    return this.getItem(CONSTANTS.ARCGIS_TOKEN) as string;
  }

  setTyC(userInvite: unknown): void {
    this.setItem(CONSTANTS.TYC, userInvite);
  }
  getUser() {
    return this.getItem(CONSTANTS.USER);
  }

  getPermissions() {
    return this.getItem(CONSTANTS.PERMISSIONS);
  }

  getTyC() {
    return this.getItem(CONSTANTS.TYC);
  }

  removeTyC() {
    this.removeItem(CONSTANTS.TYC);
  }
  removeRefreshToken() {
    this.removeItem(CONSTANTS.REFRESH_TOKEN);
  }

  removeToken() {
    this.removeItem(CONSTANTS.TOKEN);
  }

  removeUser() {
    this.removeItem(CONSTANTS.USER);
  }

  removePermissions() {
    this.removeItem(CONSTANTS.PERMISSIONS);
  }

  removeArcgisToken() {
    this.removeItem(CONSTANTS.ARCGIS_TOKEN);
  }
}
