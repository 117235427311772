import { Injectable } from '@angular/core';
import { HttpService } from '@app/core/http/http.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SystemService {
  private basePath = 'ping';

  constructor(protected httpService: HttpService) {}

  
  public ping(): Observable<string> {
    return this.httpService.get<string>(
      `${this.basePath}`,
    );
  }
}
