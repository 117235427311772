import { inject } from '@angular/core';
import { AuthService } from '../services/auth/auth.service';
import { IUser } from '@app/modules/auth/interfaces/login.auth.interfaces';
import { CanActivateFn, Router } from '@angular/router';

export function termsAndConditionsGuard(): CanActivateFn {
  return () => {
    const authService = inject(AuthService);
    const router = inject(Router);
    const user = authService.getUser() as IUser;
    if (user?.terms_and_conditions) {
      return true;
    }
    router.navigateByUrl('/terms-and-conditions');
    return false;
  };
}

export function notTermsAndConditionsGuard(): CanActivateFn {
  return () => {
    const authService = inject(AuthService);
    const user = authService.getUser() as IUser;
    if (user && !user.terms_and_conditions) {
      return true;
    }
    return false;
  };
}
