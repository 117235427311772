<div class="col text-start p-4 border-box">

  <h3>Sustainable by AGD Platform</h3>

  <h4>Terms and Conditions of Use and Data Protection</h4>
  <p>Welcome to the Sustainable by AGD website. Before using this website, we ask you to carefully read the following terms and conditions of use and data protection. By accessing and using this website, you agree to be bound by these terms and conditions. If you do not agree with any of them, we strongly recommend that you do not use this website.</p>

  <mat-divider></mat-divider>

  <h4>1) Use of the Website</h4>
  <p><i>1.1.</i> Restricted access: This website is intended exclusively for use by users belonging to and/or authorized by {{companyName}} (hereinafter the Company). Access to the site is restricted and will only be permitted to registered users authorized by the Company.</p>
  <p><i>1.2.</i> Access and Registration: To access the website's functionalities, it is necessary to be registered, which involves providing personal information such as Name, Last Name, phone number, position or function in the company, and email. By registering, you guarantee that the information provided is accurate, complete, and up-to-date, and is presented as a sworn statement. AGD is not responsible for the accuracy of the Personal Data provided by the Users.</p>
  <p><i>1.3.</i> It is strictly prohibited for an authorized user to share their access key or allow an unauthorized third party to browse the website. If such a situation is detected, AGD will be empowered to revoke the authorization of said user and suspend access to the website until the situation is clarified, even being entitled to claim damages that such actions may cause.</p>
  <p><i>1.4.</i> Users ({{companyName}} customers) and their duly authorized employees are obliged to maintain the confidentiality of their access credentials and not share their login information with third parties. They may not be replaced by unauthorized third parties once logged in. AGD S.A. is not responsible for any consequences arising from the misuse of login information, password, and/or any other access data and shall be entitled to claim damages resulting from such unauthorized actions. Furthermore, users undertake to use the website ethically, responsibly, and legally, without violating the rights of third parties or engaging in activities that may damage or compromise the integrity of the site and/or AGD and its shareholders and directors, being responsible for the breach of these obligations, and may even incur the liability of {{companyName}}.</p>
  <p><i>1.5.</i> If the User becomes aware of or suspects a breach of the confidentiality of their Passwords, they must immediately change them and notify AGD. The User undertakes to hold AGD (its officers, directors, or shareholders) harmless from any claim, damage, and/or loss directly or indirectly arising from the disclosure of their Passwords and/or from the failure to comply with the confidentiality obligation established in this clause, and/or from the use of such Passwords by unregistered third parties, and/or by representatives of the User company exceeding their powers and/or contravening instructions for use.</p>
  <p><i>1.6.</i> From the acceptance of these Terms and Conditions, the User assumes, acknowledges, and accepts full responsibility for any losses that may arise, especially if their Username is used in any way other than that intended in the object of this agreement, even if they are merely erroneous, as well as for any potential damages that may be caused to AGD.</p>
  <p><i>1.7.</i> The User declares to be aware of and accept all risks of unauthorized third parties accessing the information corresponding to their User Account, thus exempting AGD from any liability in this regard, knowing that AGD has made its best technical efforts to ensure that the information included in the system maintains its highest possible degree of confidentiality.</p>
  <p><i>1.8.</i> AGD expressly reserves the right to make claims for any type of breach of the obligations assumed herein.</p>
  <p><i>1.9.</i> Restrictions: It is prohibited to use the website in a manner that may damage, disable, overload, or harm the site or interfere with its use to the detriment of other users. Fraudulent, deceptive, or illegal activities related to the use of the website are not permitted.</p>

  <mat-divider></mat-divider>

  <h4>2) Personal Data Protection</h4>
  <p><i>2.1.</i> Collection and Processing of Data: By using the website, you agree to the collection and processing of your personal data, which will be processed in accordance with Argentine legislation. The legislation indicates how personal data provided by users will be collected, used, and disclosed, as well as the rights you have regarding your personal data.</p>
  <p><i>2.2.</i> Purpose of Processing: The purpose of processing your personal data is exclusively to manage your registration on the website.</p>
  <p><i>2.3.</i> Data Transfer: AGD S.A. will not transfer your personal data to third-party service providers.</p>

  <mat-divider></mat-divider>

  <h4>3) User Rights</h4>
  <p><i>3.1.</i> User Rights: According to Argentine data protection regulations, you have the right to access, rectify, and delete your personal data. You also have the right to object to the processing of your personal data and to revoke your consent at any time. You can exercise your rights by sending a written request through the contact channels provided on the AGD S.A. website.</p>
  <p><i>3.2.</i> Exercising Your Rights: To exercise your rights, you must provide the necessary documentation to verify your identity. We commit to respond to your request within the deadlines established by Argentine data protection regulations.</p>

  <mat-divider></mat-divider>

  <h4>4) Data Security</h4>
  <p><i>4.1.</i> Security Measures: We implement appropriate technical, organizational, and legal measures to protect your personal data against destruction, loss, alteration, unauthorized access, or disclosure.</p>

  <mat-divider></mat-divider>

  <h4>5) About the Website</h4>
  <p><i>5.1.</i> Verification of Sustainable Origin: The main purpose of this website is to provide transparent verification of the sustainable origin of the raw material used in the production of soybean flour. The site will display a reverse sequence, from the product shipment to the original field where the raw material was harvested. Historical photos of the fields will be shown to demonstrate to users that these fields have always been used for cultivation and have not been deforested for this purpose. These images are based on the period from 31/12/2010 to the present, and some images from before the mentioned period may be included for technical purposes.</p>

  <mat-divider></mat-divider>

  <h4>6) Cookie Usage</h4>
  <p><i>6.1.</i> Cookies: Our portal does not use cookies directly, nor does it collect personal information through them. However, some technological components used by the website may use cookies.</p>
  <p><i>6.2.</i> Tracking Technologies: Although we do not use cookies directly, we may use other tracking technologies to collect information about the use of the website, such as IP addresses, type of browser used, pages visited, and browsing times. These technologies help us improve the performance and functionality of the website but do not collect personally identifiable information.</p>
  <p><i>6.3.</i> Browser Configuration: If you wish to disable cookies or adjust your browser settings to receive notifications when cookies are sent, you can do so through your browser's settings options. However, please note that disabling cookies may affect the functionality of the website and limit your user experience.</p>

  <mat-divider></mat-divider>

  <h4>7) Copyright</h4>
  <p><i>7.1.</i> Ownership of Information: All content, texts, graphics, images, logos, videos, audios, and other materials published on this portal are the exclusive property of AGD S.A. or third parties who have granted corresponding rights to AGD S.A. These materials are protected by copyright laws and other applicable laws.</p>
  <p><i>7.2.</i> Restricted Use: Reproduction, distribution, modification, display, transmission, publication, or any other unauthorized use of the materials without the prior written consent of AGD S.A. is strictly prohibited. Any unauthorized use of the materials may constitute a violation of copyright and other applicable laws.</p>
  <p><i>7.3.</i> Permitted Use: You are granted a limited, non-exclusive right to access and use the materials published on this portal solely for commercial and non-personal purposes. No ownership rights are granted to you over the materials, and any other use of them without the prior written consent of AGD S.A. will be prohibited.</p>
  <p><i>7.4.</i> Infringement Notification: AGD S.A. respects the intellectual property rights of third parties and expects users of the portal to do the same. If you believe that any content published on the portal infringes your copyright or other intellectual property rights, we ask you to notify us immediately so that we can take appropriate action.</p>
  <p><i>7.5.</i> User-Generated Content: If portal users share or submit their own content, they retain copyright ownership of their content. However, by sharing or submitting content to the portal, they grant AGD S.A. a non-exclusive, worldwide, royalty-free, and sublicensable license to use, reproduce, modify, adapt, publish, translate, distribute, and display such content in connection with the operation and promotion of the portal.</p>

  <mat-divider></mat-divider>

  <h4>8) Confidentiality</h4>
  <p><i>8.1.</i> The information and images provided on this website are confidential and protected by intellectual property rights. Authorized users may only use this information for internal purposes related to the verification of the sustainable origin of the raw material. Unauthorized reproduction, distribution, or disclosure of any content on the site under any means is strictly prohibited. Any violation of this obligation will not only compromise the responsibility of the respective user but also that of {{companyName}}, with AGD being empowered to make extrajudicial or judicial claims that may be appropriate.</p>
  <p><i>8.2.</i> In line with the above, given the commercial sensitivity of the information subject to this agreement, both {{companyName}} and the Users specifically assume an obligation of confidentiality as follows:</p>
  <p><i>8.2.1.</i> The Informing Party – AGD – will make available to the Receiving Party – {{companyName}} and/or users – information related to the objective indicated in the preceding clause 1.</p>
  <p><i>8.2.2.</i> "Transmitted Information" shall mean any information, oral or written, communicated by one party ("Informing Party") to the other ("Receiving Party") for evaluation, analysis, and use by {{companyName}} and/or the users in fulfillment of the objective set forth in clause 4.</p>
  <p><i>8.2.3.</i> The Receiving Party undertakes to:</p>
  <p><i>8.2.3.1.</i> Maintain the confidential nature of the Transmitted Information and not disclose it to any person outside the scope of this agreement without the written consent of the Informing Party.</p>
  <p><i>8.2.3.2.</i> Use the Transmitted Information exclusively for tasks aimed at fulfilling the objective indicated in statement (i) of this agreement.</p>
  <p><i>8.2.3.3.</i> Disclose the Transmitted Information only to those persons who need to know it for the purpose for which it was provided, transmitting to them the same confidentiality commitment regarding the Transmitted Information. These persons shall have the obligations provided herein, and the Receiving Party shall be responsible for them.</p>
  <p><i>8.2.4.</i> The Receiving Party shall be released from its obligation to maintain secrecy regarding the Transmitted Information if it becomes publicly known without this resulting from a breach by the Receiving Party or a third party subject to a confidentiality obligation regarding the identity of the Informing Party.</p>
  <p><i>8.2.5.</i> It shall also be released when there is a legal obligation to disclose the Transmitted Information. In this case, the Receiving Party may disclose only the minimum Confidential Information necessary to comply with the requirement imposed on it, provided that it immediately notifies the Informing Party of such circumstances upon becoming aware of the requirement. This right may be exercised by the Receiving Party no earlier than the day immediately preceding the deadline for providing such information, and only if the requirement persists.</p>
  <p><i>8.2.6.</i> The Receiving Party acknowledges that: The Transmitted Information may contain errors or be inapplicable to the purpose for which it was prepared.</p>
  <p><i>8.2.6.1.</i> The duty of confidentiality of the Transmitted Information is of great importance to the Informing Party.</p>
  <p><i>8.2.7.</i> The Informing Party acknowledges that:</p>
  <p><i>8.2.7.1.</i> Deliverables, working papers, and information by electronic means or by any other means shall be exclusively accessible to the responsible parties of the Receiving Party.</p>
  <p><i>8.2.7.2.</i> It shall respect the procedures mentioned above.</p>
  <p><i>8.3.</i> The obligations set forth in this agreement shall govern not only for the duration of the analysis related to point (i) herein, but their obligations shall remain in force after the termination of the relationship between the parties for any reason, in such a way that the Receiving Party shall not be able to disclose, divulge, exchange, or otherwise make known the Transmitted Information or the identity of the Informing Party to third parties; even after the expiration of the term related to point (i) herein. Similarly, the Informing Party shall not disclose, divulge, exchange, or otherwise make known the identity of the Receiving Party or any information that it may have eventually received from it to third parties; even after the expiration of the term related to point (i) herein.</p>
  <p><i>8.4.</i> Failure to comply with any of the clauses of this agreement shall be considered to cause irreparable harm to both parties and, therefore, in the event of a breach by either party, the complying party shall be empowered to request the necessary precautionary measures to stop such conduct, as well as any other legal remedy permitted by law and to claim damages.</p>
  <p><i>8.5.</i> The obligation of confidentiality may not be assigned or transferred to third parties without the written and express consent of the other party.</p>

  <mat-divider></mat-divider>

  <h4>9) Use of Information</h4>
  <p><i>9.1.</i> The information published on the portal, including but not limited to personal data, documents, images, and any other material, may only be used for the commercial purposes authorized by AGD S.A. and in accordance with the terms and conditions set forth in this document. You may not copy, reproduce, distribute, transmit, display, sell, license, or exploit the site's content in any way without the prior written consent of AGD S.A.</p>

  <mat-divider></mat-divider>

  <h4>10) Modifications</h4>
  <p><i>10.1.</i> We reserve the right to modify or update these terms and conditions at any time without prior notice. We recommend periodically reviewing these terms and conditions to stay informed of any changes, which will be mandatory and binding upon their publication on the website.</p>

  <mat-divider></mat-divider>

  <h4>11) Limitation of Liability</h4>
  <p><i>11.1.</i> AGD S.A. shall not be liable for any direct, indirect, incidental, consequential, or special damages arising from the use or inability to use this website, even if previously advised of the possibility of such damages.</p>
  <p><i>11.2.</i> The technologies used in the development of this website are secure, recognized, and of proven track record. However, like any technology, they are not infallible. Despite our efforts to maintain and update these technologies, we cannot guarantee that they will be error-free at all times. Therefore, our company does not assume responsibility for failures in such technologies that may affect the functionality of the site or the user experience.</p>

  <mat-divider></mat-divider>

  <h4>12) General Prohibitions</h4>
  <p><i>12.1.</i> It is strictly prohibited for any user, under penalty of civil and/or criminal liability, to perform, either by themselves or through other means or third parties, the following activities:</p>
  <p><i>12.2.</i> Copy, print, save, modify, adapt, translate, or in any way use data, website content, or its database and/or source code, except as strictly necessary to preserve records and/or system functionalities.</p>
  <p><i>12.3.</i> Perform any reverse engineering activity on the website and its components, screen scraping, data mining, data collection, aggregation, or indexing, or use other automated means to access the System or its database.</p>
  <p><i>12.4.</i> Interfere with or violate any intellectual property rights of AGD, third parties, or other users.</p>
  <p><i>12.5.</i> Harvest or collect personal information about visitors or users of the website, or in any way affect their privacy rights without their express consent, including the use of any robot, spider, site search, or retrieval request, or any other manual or automatic device or process to retrieve, index, or extract data.</p>
  <p><i>2.6.</i> Defame, harass, stalk, or threaten any user.</p>
  <p><i>12.7.</i> Transmit or make available on the website any virus, worm, Trojan horse, time bomb, web bug, spyware, or any other code, file, or computer program that may or is intended to damage or hijack the operation of any hardware, software, or telecommunications equipment, or any other potentially harmful, disruptive, or invasive code or component.</p>
  <p><i>12.8.</i> Interfere with or disrupt the operation of the website, or the servers or networks hosting it, or disobey any requirements, procedures, policies, or regulations of such servers or networks.</p>
  <p><i>12.9.</i> Create a database by systematically downloading and storing all or part of the website's content.</p>
  <p><i>12.10.</i> Use the website for any illegal, immoral, violating the Terms and Conditions, or not expressly authorized purpose.</p>

  <mat-divider></mat-divider>

  <h4>13) Termination of the Relationship Generated through the Website.</h4>
  <p><i>13.1.</i> AGD and {{companyName}} may terminate the relationship arising from the acceptance of these Terms and Conditions at any time during the validity of their commercial relationship, without giving any reason, which will imply, in turn, the immediate closure of the User's account(s).</p>
  <p><i>13.2.</i> Likewise, AGD may, in the event of any User's breach of the conditions resulting from the acceptance of these Terms and Conditions, or of the applicable legislation in the matter, terminate the authorization to use the website and its functionalities without any prior notice, reserving the right to claim damages caused by such breach to both the User in question and {{companyName}}.</p>

  <mat-divider></mat-divider>

  <h4>14) Applicable Law and Jurisdiction</h4>
  <p><i>14.1.</i> These Terms and Conditions shall be governed by Argentine law, and any dispute relating to the interpretation or execution thereof shall be settled by the ordinary courts of the City of Río Cuarto, Province of Córdoba, Argentina.</p>

  <mat-divider></mat-divider>

  <h4>15) Address</h4>
  <p><i>15.1.</i> For any event or matter related to the interpretation and/or execution of these Terms and Conditions, AGD S.A. has its registered address at Int. Adrián Pascual Urquía 149, General Deheza, Province of Córdoba, Argentina, {{companyText}} and with respect to the User, all communications made to the email address provided at the time of registration shall be valid and legally effective, in accordance with the provisions of point 1.</p>

  <mat-divider></mat-divider>

  <p>By using this website, you confirm that you have read, understood, and accepted these terms and conditions in their entirety, as well as the collection and processing of your personal data in accordance with our privacy policy.</p>
  <p>If you have any questions or concerns about these terms and conditions or our privacy policy, you can contact us through the contact channels provided on the website.</p>

</div>
