import { AuthService } from '@app/core/services/auth/auth.service';
import { AfterViewInit, Component, inject } from '@angular/core';
import { TermsAndConditionsTextComponent } from '@app/shared/terms-and-conditions-text/terms-and-conditions-text.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { SpinnerComponent } from '@shared/spinner/spinner.component';
import { IUserInvite } from '@app/modules/auth/interfaces/login.auth.interfaces';
import { Router } from '@angular/router';
import { UserDataService } from '@app/core/services/user-data/user-data-service';

@Component({
  selector: 'app-terms-and-conditions-logged-in',
  templateUrl: './terms-and-conditions-logged-in.component.html',
  styleUrls: ['./terms-and-conditions-logged-in.component.sass'],
  standalone: true,
  imports: [
      TermsAndConditionsTextComponent,
      MatToolbarModule,
      SpinnerComponent,
  ],
})
export class TermsAndConditionsLoggedInComponent implements AfterViewInit {
  public showSpinner = true;
  public authService: AuthService = inject(AuthService);
  public user: IUserInvite;
  constructor(private userDataService:UserDataService) {
    this.user = this.userDataService.getTyC() as IUserInvite;
  }

  ngAfterViewInit(): void {
    setTimeout(() => (this.showSpinner = false), 2000);
  }
}
