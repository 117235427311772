import { FormControl } from '@angular/forms';

export interface ILoginFormGroup {
  email: FormControl<string | null>;
  password: FormControl<string | null>;
}

export interface IRecoveryPasswordFormGroup {
  email: FormControl<string | null>;
}

export interface IRegisterUserFormGroup {
  agree: FormControl<boolean | null>;
  name: FormControl<string | null>;
  email: FormControl<string | null>;
  company: FormControl<string | null>;
  user_position_id: FormControl<string | null>;
  password: FormControl<string | null>;
  confirmPassword: FormControl<string | null>;
  phone: FormControl<string | null>;
}

export interface IResetPasswordFormGroup {
  password: FormControl<string | null>;
  confirmPassword: FormControl<string | null>;
}

export interface ILoginResponse {
  methods: ILoginResponseMethods;
  token: string;
}

export interface CodeValidationForm {
  code: FormControl<string | null>;
}

export interface ILoginResponseMethods {
  email: string;
  phone: string;
}

export interface IValidateCodeResponse {
  user: IUser;
  permissions: Permissions;
  token: string;
  refreshToken: string;
  arcgisToken: string;
  ICompany: ICompany;
}

export interface ICompany {
  id: number;
  name: string;
  legal_address: string;
  companiesShipmentsAllocations: ICompaniesShipmentsAllocations[];
  role_id: number;
  role: IRole;
  managedBy: IManagedCompany[];
  manages: IManagedCompany[];
}

export interface IManagedCompany {
  id: number;
  name: string;
  role_id: number;
  legal_address?: string;
  role?: IRole;
}

export interface ICompaniesShipmentsAllocations {
  allocated_quantity: number;
}
export interface IUser {
  company: ICompany;
  company_id: number;
  id: number;
  email: string;
  name: string;
  phone: string;
  terms_and_conditions: boolean;
  status: boolean;
  position: IUserPosition;
}

export interface IUserPosition {
  id: number;
  name: string;
}

export interface IUserUpdate {
  user: IUser;
  permissions: Permissions;
}

export interface IUserUpdateForm {
  name: FormControl;
  phone: FormControl;
  user_position_id: FormControl;
}
export interface IUserInvite {
  email: string;
  createdAt: Date;
  expiration_date: Date;
  id: number;
  company: ICompany;
  expired?: boolean;
  updatedAt: Date,
}

export interface IRole {
  id: number;
  tag: RoleType;
  name: string;
}
export const ROLES = {
  IMPORTER: 'IMPORTER',
  ADMINISTRATOR: 'ADMINISTRATOR',
  CONSUMER: 'CONSUMER',
};

export const AGD_DOMAIN = 'agd.com.ar';
export const AGD_NAME = 'AGD';
export type RoleType = 'IMPORTER' | 'ADMINISTRATOR' | 'CONSUMER';

export type SideBarOptionsArr = SideBarOption[];

export interface SideBarOption {
  icon: string;
  optionTitle: string;
  routerLink: string;
  permissionName?: string;
}

export type Permissions = string[];

export type TokenResponse = {
  token: string;
  refreshToken: string;
};
