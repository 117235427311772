<header>
  <mat-toolbar class="px-0">
    <div class="flex-grow-1">
      <h1 class="ms-5">
        SUSTAINABLE <span class="text-lowercase">by</span> AGD
      </h1>
    </div>
  </mat-toolbar>
</header>

<div [hidden]="!showSpinner">
  <app-spinner></app-spinner>
</div>

<section [hidden]="showSpinner" class="p-5">
  <article class="m-0 p-0">
    <h2 class="fw-medium">Terms and Conditions</h2>
    <div
      class="tyc-wrap row-gap-0 row-cols-1 mx-auto rounded-3 w-50 bg-white gunmetal overflow-y-auto scrollbar">
      <app-terms-and-conditions-text [user]="user"></app-terms-and-conditions-text>
    </div>
  </article>
</section>
