import {
  HttpBackend,
  HttpClient,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment/environment';
import { Observable } from 'rxjs';
import { UserDataService } from '../services/user-data/user-data-service';
import { HttpRequestParams } from '@app/utils/http-service.interface';

export interface RequestOptions {
  headers: HttpHeaders;
  params?: HttpParams;
}

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  public apiUrl: string;
  public httpWithoutInterceptor: HttpClient;
  protected headers: HttpHeaders;
  private token = '';

  constructor(
    public http: HttpClient,
    public httpBackend: HttpBackend,
    protected userDataService: UserDataService
  ) {
    this.headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Accept', 'application/json');

    this.apiUrl = environment.API_URL;
    this.token = this.userDataService.getToken();
    this.httpWithoutInterceptor = new HttpClient(httpBackend);
  }

  public resetToken() {
    this.token = '';
  }

  public setToken(token: string) {
    this.token = token;
    this.setHeader('Authorization', `Bearer ${token}`);
  }

  public setHeader(headerName: string, headerValue: string | string[]): void {
    this.headers = this.headers.set(headerName, headerValue);
  }

  public getHeaders(): HttpHeaders {
    return this.headers;
  }

  /**
   *
   * @param params
   * @returns
   */
  private getRequestOptions(params?: HttpRequestParams): {
    headers: HttpHeaders;
    params?: HttpParams;
  } {
    if (this.token) {
      this.setHeader('Authorization', `Bearer ${this.token}`);
    }
    let httpParams: HttpParams = new HttpParams();

    if (params) {
      const keys: string[] = Object.keys(params);
      keys.forEach((keyParam: string) => {
        httpParams = httpParams.set(keyParam, params[keyParam] ?? '');
      });
    }
    return { headers: this.headers, params: httpParams };
  }

  /**
   *
   * @param url
   * @param params
   * @returns
   */
  public get<T>(url: string, params?: HttpRequestParams): Observable<T> {
    const requestOptions = this.getRequestOptions(params);
    return this.http.get<T>(`${this.apiUrl}/${url}`, requestOptions);
  }

  /**
   *
   * @param url
   * @param body
   * @param interceptor
   * @returns
   */
  public post<T>(
    url: string,
    body: object,
    interceptor = true,
    headers?: HttpHeaders
  ): Observable<T> {
    const requestOptions = this.getRequestOptions();
    if (body instanceof FormData) {
      requestOptions.headers = requestOptions.headers.delete('content-type');
    }
    if (headers) {
      requestOptions.headers = headers;
    }

    const http = interceptor ? this.http : this.httpWithoutInterceptor;
    return http.post<T>(`${this.apiUrl}/${url}`, body, requestOptions);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public downloadFile(url: string): Observable<any> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const opts: any = {
      headers: this.getRequestOptions().headers,
      observe: 'response' as 'body',
      responseType: 'blob' as string,
    };

    return this.http.get<unknown>(`${this.apiUrl}/${url}`, opts);
  }
  /**
   *
   * @param url
   * @param body
   * @param interceptor
   * @returns
   */
  public put<T>(url: string, body?: object, interceptor = true): Observable<T> {
    const requestOptions = this.getRequestOptions();
    const http = interceptor ? this.http : this.httpWithoutInterceptor;
    return http.put<T>(`${this.apiUrl}/${url}`, body, requestOptions);
  }

  /**
   *
   * @param url
   * @param body
   * @param interceptor
   * @returns
   */
  public patch<T>(
    url: string,
    body: object,
    interceptor = true
  ): Observable<T> {
    const requestOptions = this.getRequestOptions();
    const http = interceptor ? this.http : this.httpWithoutInterceptor;
    return http.patch<T>(`${this.apiUrl}/${url}`, body, requestOptions);
  }

  public delete<T>(url: string): Observable<T> {
    const requestOptions = this.getRequestOptions();
    return this.http.delete<T>(`${this.apiUrl}/${url}`, requestOptions);
  }
}
