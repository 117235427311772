import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { HttpService } from '@app/core/http/http.service';
import {
  ILoginResponse,
  IUser,
  IValidateCodeResponse,
  Permissions,
  TokenResponse,
} from '@app/modules/auth/interfaces/login.auth.interfaces';
import { BehaviorSubject, EMPTY, Observable, interval, switchMap } from 'rxjs';
import { LocalStorageService } from '../localStorage/local-storage.service';
import { UserDataService } from '../user-data/user-data-service';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private basePath = 'auth';
  private _user = new BehaviorSubject<IUser | null>(null);
  private _loginData = new BehaviorSubject<ILoginResponse | null>(null);
  private _arcgis = new BehaviorSubject<string | null>(null);
  private _permissions = new BehaviorSubject<Permissions | null>(null);
  public loginData$ = this._loginData.asObservable();
  public userLoggedIn$ = this._user.asObservable();
  public arcgis$ = this._arcgis.asObservable();
  public userPermissions$ = this._permissions.asObservable();
  public permissionSummaryList: { [x: string]: boolean } = {};
  public refreshTokenSubject: BehaviorSubject<TokenResponse | null> =
    new BehaviorSubject<TokenResponse | null>(null);

  constructor(
    protected httpService: HttpService,
    private localStorageService: LocalStorageService,
    private userDataService: UserDataService,
    private dialogRef: MatDialog
  ) {
    const user = this.userDataService.getUser() as IUser;
    const permissions = this.userDataService.getPermissions() as Permissions;
    const arcgisToken = this.userDataService.getArcgisToken();
    this._arcgis.next(arcgisToken);
    this._user.next(user);
    this._permissions.next(permissions);
    this.userPermissions$.subscribe(() => {
      this.setPermissionsSummaryList();
    });
  }

  public setPermissionsSummaryList() {
    this.permissionSummaryList = {};
    this._permissions.getValue()?.forEach(item => {
      this.permissionSummaryList[item] = true;
    });
  }

  fetchArcgisTokenAndSet() {
    return this.getArcgisTokenFromApi().pipe(
      switchMap(response => {
        this._arcgis.next(response.token);
        this.userDataService.setArcgisToken(response.token);
        return EMPTY;
      })
    );
  }

  fetchArcgisTokenPeriodically(timeInterval: number) {
    return interval(timeInterval).pipe(
      switchMap(() => {
        return this.fetchArcgisTokenAndSet();
      })
    );
  }

  getArcgisTokenFromApi() {
    return this.httpService.get<any>(`${this.basePath}/arcgis-token`);
  }
  public hasPermission(name: string) {
    return this.permissionSummaryList[name];
  }
  public getPermissions(): Permissions {
    const permissions = this._permissions.value;
    if (permissions) {
      return permissions;
    } else {
      return {} as Permissions;
    }
  }
  public getUser(): IUser {
    const user = this._user.value;
    if (user) {
      return user;
    } else {
      return {} as IUser;
    }
  }

  public getArcgisToken(): string {
    const arcgis = this._arcgis.value;
    if (arcgis) {
      return arcgis;
    } else {
      return {} as string;
    }
  }

  public refreshToken(
    refreshToken: string,
    userId: number
  ): Observable<TokenResponse> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Authorization', `Bearer ${refreshToken}`);
    return this.httpService.post<TokenResponse>(
      `${this.basePath}/refresh`,
      { refreshToken, userId },
      false,
      headers
    );
  }

  public updateUserTermsAndConditions(tac: boolean) {
    const user = this._user.value as IUser;
    user.terms_and_conditions = tac;
    this._user.next(user);
    this.userDataService.setUser(user);
  }
  public async deleteLoginData(self: AuthService | null = null) {
    const selfService = self ?? this;
    const user = await selfService.userDataService.getUser() as IUser;
    if (user) {
      selfService.userDataService.removeUser();
      selfService.userDataService.removePermissions();
      selfService.userDataService.removeToken();
      selfService.userDataService.removeArcgisToken();
      selfService.userDataService.removeRefreshToken();
      selfService.httpService.resetToken();
    }
    selfService.dialogRef.closeAll();
    selfService._user.next(null);
    selfService._permissions.next(null);
  }

  public login(
    email: string,
    password: string,
    recaptcha: string
  ): Observable<ILoginResponse> {
    const body = { email, password, recaptcha };
    return this.httpService.post<ILoginResponse>(
      `${this.basePath}/login`,
      body
    );
  }

  public logout(): Observable<number> {
    const refreshToken = this.userDataService.getRefreshToken();
    const user = this.getUser();
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Authorization', `Bearer ${refreshToken}`);
    return this.httpService.post<number>(
      `${this.basePath}/logout`,
      {
        userId: user?.id,
        refreshToken,
      },
      false,
      headers
    );
  }

  public recoveryPassword(email: string, recaptcha: string): Observable<void> {
    const body = { email, recaptcha };
    return this.httpService.post<void>(
      `${this.basePath}/forgot-password`,
      body
    );
  }

  public resetPassword(
    password: string,
    token: string,
    email: string,
    recaptcha: string
  ): Observable<void> {
    const body = { password, token, email, recaptcha };
    return this.httpService.post<void>(`${this.basePath}/reset-password`, body);
  }

  public set2FALoginMethod(method: string) {
    this.localStorageService.setItem('2FALoginMethod', method);
  }
  public get2FALoginMethod(): string {
    return this.localStorageService.getItem('2FALoginMethod') as string;
  }
  public sendLoginCode(
    method: string,
    token: string,
    recaptcha: string
  ): Observable<boolean> {
    const body = { token, recaptcha };
    return this.httpService.post<boolean>(
      `${this.basePath}/2FA/${method}`,
      body
    );
  }

  public removeLoginData() {
    this.localStorageService.removeItem('loginData');
    this._loginData.next(null);
  }

  public setLoginData(data: ILoginResponse): void {
    this.localStorageService.setItem('loginData', data);
    this._loginData.next(data);
  }

  public remove2FALoginMethod(): void {
    this.localStorageService.removeItem('2FALoginMethod');
  }

  public saveUserLoginData(data: IValidateCodeResponse) {
    this._user.next(data.user);
    this._arcgis.next(data.arcgisToken);
    this._permissions.next(data.permissions);
    this.userDataService.setArcgisToken(data.arcgisToken);
    this.userDataService.setUser(data.user);
    this.userDataService.setPermissions(data.permissions);
    this.userDataService.setToken(data.token);
    this.userDataService.setRefreshToken(data.refreshToken);
    this.httpService.setHeader('Authorization', `Bearer ${data.token}`);
  }

  public updateUserData(user: IUser, permissions: Permissions) {
    this._user.next(user);
    this.userDataService.setUser(user);
    this._permissions.next(permissions);
  }

  public async loadUserLoginData() {


      const user = this.userDataService.getUser() as IUser;
      const permissions = this.userDataService.getPermissions() as Permissions;
      const arcgisToken = this.userDataService.getArcgisToken();

      this._arcgis.next(arcgisToken);
      this._user.next(user);
      this._permissions.next(permissions);
  }

  public getLoginData(): ILoginResponse | null {
    if (this._loginData.value) {
      return this._loginData.value;
    }
    const loginData = this.localStorageService.getItem(
      'loginData'
    ) as ILoginResponse;
    if (loginData) {
      this._loginData.next(loginData);
      return loginData;
    }
    return null;
  }

  public validate2FACode(
    code: string,
    token: string,
    recaptcha: string
  ): Observable<IValidateCodeResponse> {
    const body = { code, recaptcha, token };
    return this.httpService.post<IValidateCodeResponse>(
      `${this.basePath}/2FA/validate-code`,
      body
    );
  }
}
