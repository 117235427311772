import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';

import { HttpService } from '../http/http.service';
import { AuthService } from '../services/auth/auth.service';
import { SnackBarService } from '../services/snackbar/snackbar.service';
import { UserDataService } from '../services/user-data/user-data-service';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptor implements HttpInterceptor {
  private isRefreshing = false;

  constructor(
    public httpService: HttpService,
    public authService: AuthService,
    public userDataService: UserDataService,
    private router: Router,
    private snackBarService: SnackBarService
  ) {}

  public cloneWithNewToken(req: HttpRequest<unknown>): HttpRequest<unknown> {
    const token = this.userDataService.getToken();
    let newReq = req;
    if (token) {
      newReq = req.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
    }
    return newReq;
  }
  public intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    let newReq = this.cloneWithNewToken(req);

    return next
      .handle(newReq)
      .pipe(catchError((err: any) => this.handleError(err, newReq, next)));
  }

  /**
   * Handles HTTP errors and performs necessary actions based on the error status.
   * @param error - The HTTP error response.
   * @param req - The HTTP request.
   * @param next - The HTTP handler.
   * @returns An observable of the HTTP event.
   */
  private handleError(
    error: HttpErrorResponse,
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    // Handle no connection error
    if (error.status === 0) {
      this.snackBarService.show('Error connecting to the server.', 'Close');
      return EMPTY;
    }
    if (error.status === 503) {
      this.router.navigateByUrl('/system/maintenance');
    }
    // Handle unauthorized error and refresh token
    if (
      error.status === 401 &&
      ['auth/validate-code', '/auth/login'].indexOf(this.router.url) === -1
    ) {
      return this.handle401Error(req, next);
    }

    // Handle other errors
    this.isRefreshing = false;
    return throwError(() => error);
  }

  handle401Error(req: HttpRequest<unknown>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.authService.refreshTokenSubject.next(null);
      const refreshToken = this.userDataService.getRefreshToken();
      const user = this.authService.getUser();
      return this.authService.refreshToken(refreshToken, user.id).pipe(
        catchError(error => {
          this.snackBarService.show(
            'Your session has expired. Please log in again to continue.',
            'Close'
          );
          this.authService.deleteLoginData();
          this.router.navigateByUrl('/auth/login');
          this.isRefreshing = false;
          return throwError(() => error); // Devolver el error
        }),
        switchMap(res => {
          this.userDataService.setToken(res.token);
          this.userDataService.setRefreshToken(res.refreshToken);
          this.isRefreshing = false;
          this.authService.refreshTokenSubject.next(res);
          this.httpService.setToken(res.token);
          let newReq = this.cloneWithNewToken(req);
          return next.handle(newReq);
        })
      );
    }
    if (req.url.includes('auth/refresh')) {
      this.snackBarService.show(
        'Your session has expired. Please log in again to continue.',
        'Close'
      );
      this.authService.deleteLoginData();
      this.router.navigateByUrl('/auth/login');
      this.isRefreshing = false;
    }
    // Queue all the requests that are being sent while refreshing the token
    // and handle them once the refreshing is completed
    return this.authService.refreshTokenSubject.pipe(
      filter(token => token !== null),
      take(1),
      switchMap(() => {
        let newReq = this.cloneWithNewToken(req);
        return next.handle(newReq);
      })
    );
  }
}
