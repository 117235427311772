export const environment = {
  RECAPTCHA_V3_SITE_KEY: '6LeqI0opAAAAALBL9CX_PYnvy8zkV-sw0xhK7Adw',
  LOGIN_SEND_CODE_TIME: 60,
  API_URL: 'https://trdtest.agd.com.ar/api',
  FRONT_URL: 'https://trdtest.agd.com.ar',
  LANDSAT_URL:
    'https://landsat2.arcgis.com/arcgis/rest/services/Landsat/MS/ImageServer',
  MAP_SWIPE_VIEW_URL:
    'https://gis.agd.com.ar/server/rest/services/UNCO_SUST/PortalTraders/FeatureServer/2',
  SINGLE_MAP_VIEW_URL:
    'https://gis.agd.com.ar/server/rest/services/UNCO_SUST/PortalTraders/FeatureServer/0',
  VERTICES_MAP_VIEW_URL:
    'https://gis.agd.com.ar/server/rest/services/UNCO_SUST/PortalTraders/FeatureServer/1',
  APP_NAME: 'Sustainable by AGD',
  // ARCGIS expiration time is in minutes
  ARCGIS_TOKEN_INTERVAL: 120,
  SOYBEAN_YIELD: 0.7,
  DEFAULT_IDLE_SECONDS: 60*15,
  DEFAULT_TIMEOUT_SECONDS:60,
};
