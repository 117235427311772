import { Component, NgZone, OnInit } from '@angular/core';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { environment } from '@environment/environment';
import { MatIconRegistry } from '@angular/material/icon';
import es from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { SnackBarService } from './core/services/snackbar/snackbar.service';
import { AuthService } from './core/services/auth/auth.service';
import { ActivationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { SystemService } from './core/services/system/system.service';
import { ICONS } from 'assets';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass'],
})
export class AppComponent implements OnInit {
  broadcastChannel: BroadcastChannel = new BroadcastChannel('session');

  constructor(
    private titleService: Title,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    private idle: Idle,
    private snackBar: SnackBarService,
    private authService: AuthService,
    private router: Router,
    private systemService: SystemService,
    private ngZone: NgZone

  ) {
    // Set Titles
    this.titleService.setTitle(`${environment.APP_NAME}`);

    // Icons for Material Symbols (Use it with the fontSet="material-symbols")
    iconRegistry.registerFontClassAlias(
      'material-symbols',
      'material-symbols-outlined'
    );

    // Custom icons
    iconRegistry.addSvgIcon(
      'icon_spinner',
      sanitizer.bypassSecurityTrustResourceUrl(ICONS.spinner)
    );
    iconRegistry.addSvgIcon(
      'icon_whatsapp',
      sanitizer.bypassSecurityTrustResourceUrl(ICONS.whatsapp)
    );
    iconRegistry.addSvgIcon(
      'icon_soja',
      sanitizer.bypassSecurityTrustResourceUrl(ICONS.soja)
    );
    iconRegistry.addSvgIcon(
      'icon_plant',
      sanitizer.bypassSecurityTrustResourceUrl(ICONS.plant)
    );
    iconRegistry.addSvgIcon(
      'icon_check_successfully',
      sanitizer.bypassSecurityTrustResourceUrl(ICONS.check_successfully)
    );
    iconRegistry.addSvgIcon(
      'icon_maintenance',
      sanitizer.bypassSecurityTrustResourceUrl(ICONS.maintenance)
    );
    iconRegistry.addSvgIcon(
      'icon_expired',
      sanitizer.bypassSecurityTrustResourceUrl(ICONS.expired)
    );
    iconRegistry.addSvgIcon(
      'icon_logout',
      sanitizer.bypassSecurityTrustResourceUrl(ICONS.logout)
    );
    iconRegistry.addSvgIcon(
      'icon_without_results',
      sanitizer.bypassSecurityTrustResourceUrl(ICONS.without_results)
    );
    iconRegistry.addSvgIcon(
      'icon_without_notifications',
      sanitizer.bypassSecurityTrustResourceUrl(ICONS.without_notifications)
    );
    iconRegistry.addSvgIcon(
      'icon_user_wo_results',
      sanitizer.bypassSecurityTrustResourceUrl(ICONS.user_wo_results)
    );
    iconRegistry.addSvgIcon(
      'icon_companies_wo_results',
      sanitizer.bypassSecurityTrustResourceUrl(ICONS.companies_wo_results)
    );
    iconRegistry.addSvgIcon(
      'icon_vessels_wo_results',
      sanitizer.bypassSecurityTrustResourceUrl(ICONS.vessels_wo_results)
    );
    iconRegistry.addSvgIcon(
      'icon_vessels_wo_results_alt',
      sanitizer.bypassSecurityTrustResourceUrl(ICONS.vessels_wo_results_alt)
    );

    this.ngZone.runOutsideAngular(() => {
      this.broadcastChannel.onmessage = async (event) => {
        if (event.data.type === 'userLoggedOut') {
          await this.authService.deleteLoginData(this.authService);
          this.ngZone.run(() => {
            this.router.navigateByUrl('/auth/login');
          });
          if(!event.data.inactivity) {
            this.snackBar.show('You have been logged out.', 'Close');
          }
        } else if(event.data.type === 'userLoggedIn') {
          await this.authService.loadUserLoginData().then(() => {
            this.ngZone.run(() => {
              this.router.navigateByUrl('/main/map');
            });
          });
        }
      }
    });
    this.setIdle();
    this.router.events
      .pipe(filter((event) => event instanceof ActivationEnd))
      .subscribe(() => {
        if (this.router.url.indexOf('/main') === -1 && this.router.url.indexOf('/auth/register-user/logout') === -1) {
          this.idle.stop();
        } else if (!this.idle.isRunning()) {
          this.resetIdle();
        }
      });
      window.addEventListener('userLoggedIn', () => {
        this.router.navigateByUrl('/main/map');
        this.broadcastChannel.postMessage({ type: 'userLoggedIn'});
      });
      window.addEventListener('userLoggedOut', async (event: any) => {
        if(this.idle.isRunning()) {
          this.idle.stop();
        }
        await this.authService.deleteLoginData(this.authService);
        this.router.navigateByUrl(event.detail.url);
        this.ngZone.runOutsideAngular(() => {
          this.broadcastChannel.postMessage({ type: 'userLoggedOut', inactivity: event.detail.inactivity});
        });
      });
  }
  setIdle() {
    this.idle.setIdleName('SustainableIdleStorage');
    this.idle.onIdleStart.subscribe(() => {
      this.snackBar.show('Your session will automatically close in 1 minute due to inactivity.', '', { duration: 60000 });
    });
    this.idle.onIdleEnd.subscribe(() => {
      this.snackBar.close();
    })
    this.idle.onTimeout.subscribe(() => {
      const event = new CustomEvent('userLoggedOut', { detail: {url: '/auth/login', inactivity: true }});
      window.dispatchEvent(event);
      this.snackBar.show('Your session has been closed due to inactivity.', '', { duration: 60000 });
    });
  }

  resetIdle() {
    this.idle.setIdle(environment.DEFAULT_IDLE_SECONDS);
    this.idle.setTimeout(environment.DEFAULT_TIMEOUT_SECONDS);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    this.idle.watch();
  }
  ngOnInit(): void {
    registerLocaleData(es);
    this.systemService.ping().subscribe(
      {
        next: () => {},
        error: () => {
          this.router.navigateByUrl('/system/maintenance');
          this.snackBar.show('The system is currently unavailable.', '', { duration: 60000 });
        }
      }
    );

  }
}
